import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const DonateThanksPage = () => {
  return (
    <>
      <Layout>
        <SEO title="Поддержите Развите ЗАО Театра / Donate to ZAO Theater" />
        <section>
          <h1>Спасибо / Thank You!</h1>
          <h2>Спасибо за вашу поддержку, мы ждем вас в ближайшее время!</h2>
          <hr/>
          <h2>Thank you for your support, we are waiting for you back soon!</h2>
        </section>
      </Layout>
    </>
  )
}
export default DonateThanksPage
